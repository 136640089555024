import React from 'react'
import '../assets/fonts/fonts.css'
import { connect } from 'react-redux'
import { ThemeProvider, Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme, reset } from '../styles'
import Footer from './Footer'
import '../styles/modal.css';

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.fonts.titles};
    color: ${theme.colors.text};
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-top: 3rem;
  }
  h2 {
    font-size: 2rem;
    margin: 2rem 0 3rem;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.text};
    background: ${theme.colors.background};
  }
  ::selection {
    color: ${theme.colors.text};
    background-color: ${theme.colors.selection};
  }
  a {
    color: ${theme.colors.link};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    font-style: italic;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`

const MainWrapper = styled.div`
  min-height: calc(100vh - 150px);
`

class Layout extends React.Component {

  render() {
    const { children, path } = this.props;
    
    return (
      <ThemeProvider theme={theme}>
        <Global styles={globalStyle} />
        <React.Fragment>
          <MainWrapper>
            <div>{children}</div>
          </MainWrapper>
          {
            path !== '/' && (
              <Footer />
            )
          }
        </React.Fragment>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});

export default connect(mapStateToProps, null)(Layout);