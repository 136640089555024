import styled from '@emotion/styled'

export const FooterContainer = styled.footer`
  font-family: ${props => props.theme.fontFamily};
  width: 100%;
  margin: 2rem auto 0 auto;
  padding: 2rem;
  color: ${props => props.theme.colors.grey};
  text-align: center;
  text-Transform: uppercase;
  font-size: 13px;
  > div:last-of-type {
    letter-spacing: .3px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 13px;
    > div:last-of-type {
      letter-spacing: .3px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 9px;
    > div:last-of-type {
      letter-spacing: .2px;
    }
  }
  a {
    color: ${props => props.theme.colors.black};
    font-style: normal
  }

  span {
    font-size: 15.2px;
    display: block;
    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      font-size: 11px;
    }
  }
  p {
    margin: 2rem 0;
  }
  ul {
    margin: 1rem 0 0 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      padding: 0 1.2rem;
      list-style-type: none;
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        padding: 0 .5rem;
      }
      a {
        img {
          opacity: .7;
          max-height: 50px;
          transition: .2s ease-out;
          &:hover {
            opacity: 1;
          }
          @media (max-width: ${props => props.theme.breakpoints.m}) {
            max-height: 25px;
          }
        }
      }
    }
  }
`