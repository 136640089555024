import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FooterContainer } from '../containers/Footer';

const Footer = ({ children }) => {
  const { homepagelinks } = useStaticQuery(
    graphql`
      query {
        homepagelinks: allPrismicHomepage {
          edges {
            node {
              data {
                liens {
                  image1 {
                    alt
                    url
                  }
                  lien {
                    url
                  }
                  titre {
                    text
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const links = homepagelinks.edges[0].node.data.liens;
  return (
    <FooterContainer>
      {children}
      <ul>
        {
          links.map((item, index) => {
            return (
              <li key={`item-${index}`}>
                <a rel="noreferrer" href={item.lien.url} target="_blank">
                  <img alt={`item-${index}`} src={item.image1.url} />
                </a>
              </li>
            )
          })
        }
      </ul>
      <p>&copy; 2021 mooonshiners.com</p>
    </FooterContainer>
  )
}

export default Footer;